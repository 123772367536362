import React from "react";

import FormField from "./formField.jsx";

import Button from "components/shared/Button/index.js";

import propTypes from "prop-types";

import "./form.scss";

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldValues: this.getInitialValues(props.fields),
      validationState: {},
    };
  }

  getInitialValues(fields) {
    const initialFieldValues = {};

    fields.forEach((field) => {
      initialFieldValues[field.name] = field.initialValue || "";
    });

    return initialFieldValues;
  }

  changeFieldValue = (fieldName, fieldValue) => {
    const { fieldValues } = this.state;

    fieldValues[fieldName] = fieldValue;

    this.setState({ fieldValues });
  };

  validateForm() {
    const { validationState, fieldValues } = this.state;
    let isValid = true;

    Object.entries(fieldValues).forEach(([key, value]) => {
      if (value) {
        validationState[key] = true;
      } else {
        validationState[key] = false;
        isValid = false;
      }
    });

    this.setState({ validationState });

    return isValid;
  }

  submitForm = () => {
    const { onSubmit } = this.props;
    const { fieldValues } = this.state;

    if (this.validateForm()) {
      onSubmit(fieldValues);
    }
  };

  render() {
    const { className, fields, formName, disabled, hasSubmit, messages } = this.props;
    const { validationState, fieldValues } = this.state;

    return (
      <div className={`form ${className}`}>
        {formName && <h4>{formName}</h4>}
        {fields.map((field) => (
          <FormField
            key={field.name}
            {...field}
            onChange={(fieldValue) =>
              this.changeFieldValue(field.name, fieldValue)
            }
            isInvalid={validationState[field.name] === false}
            value={fieldValues[field.name]}
          />
        ))}
        {hasSubmit && <Button onClick={this.submitForm} text="Submit" disabled={disabled} variant="round" />}
        {messages?.length ? <div> {messages.map(message => <div className={`form-message ${message.type}`}>{message.text}</div>)} </div> : ''}
      </div>
    );
  }
}

Form.defaultProps = {
  className: "",
  hasSubmit: true
};

Form.propTypes = {
  className: propTypes.string,
  fields: propTypes.arrayOf(propTypes.object),
  onSubmit: () => { },
  hasSubmit: propTypes.bool
};

export default Form;
