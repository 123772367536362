export const BLOGGER_ID = "313769346268929301";

// export const API_URL = "http://localhost:80/api/v1";
export const API_URL = process.env.REACT_APP_API_URL;


export const BLOGGER_API_URL = "https://www.googleapis.com/blogger/v3/blogs/" + BLOGGER_ID;

export const API_ROUTES = {
  AUTH: API_URL + "/auth/login/",
  CHALLENGES: API_URL + "/challenges/",
  ME: API_URL + "/auth/me/",
  USER: API_URL + "/users/",
  PROFILES: API_URL + "/profiles/",
  POSTS: BLOGGER_API_URL + "/posts",
  SUBMISSIONS: API_URL + "/submissions/"
};

export const API_KEY = "AIzaSyBtqYjmjxalnEgxu3ekN-7PadysR3Wk5kg";

export const IMAGE_API = "https://outdesign.club/imageApi";
