const ACTION_TYPES = {
  AUTH: {
    SET_STATUS: "AUTH_SET_STATUS",
    CLEAR: "AUTH_CLEAR",
    SET_TOKEN: "AUTH_SET_TOKEN",
    UNSET_TOKEN: "AUTH_UNSET_TOKEN",
    FETCH_TOKEN: "AUTH_FETCH_TOKEN",
  },
  USER: {
    SET_PROFILE: "USER_SET_PROFILE",
    SET_STATUS: "USER_SET_STATUS",
    SET_PROFILE_STATUS: "USER_SET_PROFILE_STATUS",
    CLEAR: "USER_CLEAR",
    SET_DATA: "USER_SET_DATA",
    UNSET_DATA: "USER_UNSET_DATA",
  },
  ARTICLES: {
    SET_STATUS: "ARTICLES_SET_STATUS",
    CLEAR: "ARTICLES_CLEAR",
    SET_DATA: "ARTICLES_SET_DATA",
    SET_ARTICLE: "ARTICLES_SET_ARTICLE",
    UNSET_DATA: "ARTICLES_UNSET_DATA",
  },
  ERROR: {
    SET_ERROR: "SET_ERROR",
    UNSET_ERROR: "UNSET_ERROR",
    CLEAR: "ERROR_CLEAR",
  },
  TOAST: {
    ADD_TOAST: "ADD_TOAST",
    REMOVE_TOAST: "REMOVE_TOAST",
  },
  CHALLENGES: {
    CREATE_CHALLENGE: "CHALLENGES_CREATE",
    UPDATE_CHALLENGE: "CHALLENGES_UPDATE",
    SET_STATUS: "CHALLENGES_SET_STATUS",
    SET_CHALLENGES: "SET_CHALLENGES",
    GET_CHALLENGES: "GET_CHALLENGES",
    REFRESH_CHALLENGES: "REFRESH_CHALLENGES"
  }
};

export { ACTION_TYPES };
