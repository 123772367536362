import APIHandler from "./apiHandler";

import { API_ROUTES, API_KEY } from "./const";

const userAPI = {
  getArticles: async () =>
  await APIHandler.get({ url: API_ROUTES.POSTS + "?key=" + API_KEY }).then(
      (response) => {
        return response;
      }
    ),
  getArticleById: async (payload) =>
  await  APIHandler.get({
      url: API_ROUTES.POSTS + "/" + payload + "?key=" + API_KEY,
    })
};

export default userAPI;
