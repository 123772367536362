import { ACTION_TYPES } from "constants/actionTypes";

export function localStorageMiddleware({ getState }) {
  return (next) => (action) => {
    if (action.type === ACTION_TYPES.AUTH.SET_TOKEN) {
      localStorage.setItem("token", action.payload);
    } else if (action.type === ACTION_TYPES.AUTH.UNSET_TOKEN) {
      localStorage.setItem("token", "");
    }

    return next(action);
  };
}
