import React from "react";

import AppRouter from "./components/AppRouter";

import './assets/bootstrap.min.css';
import "./App.css";


function App() {
  return <AppRouter />;
}

export default App;
