import React from "react";
import { Link } from "react-router-dom";

export class NavItem extends React.Component {
  render() {
    const { isLoggedIn, route } = this.props;
    const { path } = route;

    if (!route.labels) return;

    const label = isLoggedIn ? route.labels.loggedIn : route.labels.loggedOut;

    return (
      label && (
        <li className="main-nav__item">
          <Link to={path}>{label}</Link>
        </li>
      )
    );
  }
}

export default NavItem;
