import { ACTION_TYPES } from "constants/actionTypes";
import STATUS_TYPES from "constants/statusTypes";
import authAPI from "api/authApi";

import { setError, unsetError } from "./errors";
import { setStatus as setUserStatus, requestUserData, unsetData as unsetUserData } from "./user";
import { getError } from "shared/helpers";

export function setToken(token) {
  return {
    type: ACTION_TYPES.AUTH.SET_TOKEN,
    payload: token
  };
}

export function unsetToken() {
  return {
    type: ACTION_TYPES.AUTH.UNSET_TOKEN
  };
}

export function setStatus(status) {
  return {
    type: ACTION_TYPES.AUTH.SET_STATUS,
    payload: status
  };
}

export function doLogout() {
  return dispatch => {
    dispatch(unsetToken());
    dispatch(unsetUserData());

    dispatch(setStatus(STATUS_TYPES.INITIAL));
    dispatch(setUserStatus(STATUS_TYPES.INITIAL));
  }
}

export function requestLogin(payload) {
  return async dispatch => {
    dispatch(setStatus(STATUS_TYPES.PENDING));
    dispatch(unsetError({ type: "auth" }));

    try {
      const jsonResponse = await authAPI.doLogin(payload);

      if (!jsonResponse || !jsonResponse.token) {
        dispatch(setStatus(STATUS_TYPES.FAILED));
        dispatch(unsetToken());

        return dispatch(setError({
          type: "auth", errors: [jsonResponse.message
            || "There's been a problem"]
        }));


      } else {
        dispatch(setToken(jsonResponse.token));
        dispatch(setStatus(STATUS_TYPES.LOADED));

        return dispatch(requestUserData());
      }
    } catch (e) {
      const error = await getError(e);

      dispatch(setStatus(STATUS_TYPES.FAILED));
      dispatch(unsetToken());

      return dispatch(setError({ type: "auth", errors: [error.message] }));
    }

  };
}
