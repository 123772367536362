import { ACTION_TYPES } from "constants/actionTypes";

const initialState = {
  toasts: [],
  latestId: 0
};

export default function toastReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.TOAST.ADD_TOAST:
      const newId = state.latestId + 1;

      return {
        latestId: newId,
        toasts: [...state.toasts, { ...payload, latestId: newId }]
      };

    case ACTION_TYPES.TOAST.REMOVE_TOAST:
      return { ...state, toasts: state.toasts.filter(t => t.id !== payload) };

    default:
      return state;
  }
}
