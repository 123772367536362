import { connect } from "react-redux";
import { requestUserDataIfToken } from "redux/actions/user";

import STATUS_TYPES from "constants/statusTypes";

import { getUserData, getUserDataStatus } from "redux/selectors/user";
import { getIsLoggedIn } from "redux/selectors/auth";

import AppRouter from "./AppRouter";

const mapStateToProps = (state) => {
  const isRefreshing = () => getUserData(state).name || getUserDataStatus(state) === STATUS_TYPES.PENDING;
  
  return {
    user: getUserData(state),
    userStatus: getUserDataStatus(state),
    isLoggedIn:
      getIsLoggedIn(state) || isRefreshing()
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetUserData: () => {
      dispatch(requestUserDataIfToken());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
