import React, { useState } from "react";

import "./styles.scss";

const FileInput = ({ name, isInvalid, readonly, value, onChange }) => {

  const [file, setFile] = useState(null);

  const fileHandler = event => {
    let reader = new FileReader();
    reader.onload = function (e) {
      setFile(e.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
    onChange(event.target.files[0]);
  };

  return <div className="file-input">
    {file ? <img src={file} alt="profile" className="preview"/> : ''}
    <input
      name={name}
      readOnly={readonly}
      type="file"
      onChange={fileHandler} /></div>
}
export default FileInput;
