import React from "react";

import Box from "components/shared/Box";

const NotFound = () => {
    return <Box titleText="Not Found" variant="layout-box" className="container">
        <h3>Why are we here? While philisophers keep asking this question all the time, we must do it as well.<br /> At least when reaching a <b>404</b>.</h3>
    </Box>

}

export default NotFound;