import React from "react";
import { Link } from "react-router-dom";

import Form from "components/shared/Form/form";
import Box from "components/shared/Box";

import "./login.scss";

const formFields = [
  {
    type: "email",
    name: "email",
    label: "Email",
    errorMessage: "Invalid Email",
  },
  {
    type: "password",
    name: "password",
    label: "Password",
    errorMessage: "Invalid Password",
  },
];

class Login extends React.PureComponent {
  onSubmit = (payload) => {
    const { doRequestLogin } = this.props;

    doRequestLogin(payload);
  };

  render() {
    const { authError } = this.props;

    return (
      <Box className="login" variant="white-box" titleText="Log In">
        <Form fields={formFields} onSubmit={this.onSubmit} formName="" />
        {authError.length ? (
          <div className="alert alert-danger">{authError}</div>
        ) : (
          ""
        )}
        <Link to="/register" className="register-link">No account? Click here to register</Link>
      </Box>
    );
  }
}

export default Login;
