import React from "react";

import Box from "components/shared/Box";
import Layout from "components/shared/Layout";
import ProfileCard from "components/ProfileCard/ProfileCard";

import "./profile.scss";
import STATUS_TYPES from "constants/statusTypes";
import Loader from "components/shared/Loader";

class Profile extends React.PureComponent {
  componentDidMount() {
    const { getUserProfile, slug, profile, submissionId } = this.props;

    if (!profile || profile.slug !== slug) {
      getUserProfile({ slug, submissionId });
    }
  }

  getColumns() {
    const {
      firstName,
      lastName,
      role,
      slug,
      otherForms,
    } = this.props.profile;

    const columns = [
      {
        width: 3,
        component: (
          <div>
            <ProfileCard
              {...{ firstName, lastName, slug, role }}
            />
          </div>
        ),
      },
      {
        width: 9,
        component: (
          <Box
            title={{ text: "Latest Submissions By " + firstName, type: "small" }}
          >
            {otherForms?.length ? otherForms.map((f) => (
              <a className="other-form" href={`/${slug}/${f.id}`}>
                {f.name}
              </a>
            )) : <div>
              <h2>This user has no public submissions yet. </h2>
              <h4> Once a challenge is done, submissions for it will become public</h4></div>}
          </Box>
        ),
      },
    ];

    return columns;
  }

  getColumns404() {
    const columns = [
      {
        width: 3,
        component: (
          <div className="error-404">404</div>
        ),
      },
      {
        width: 9,
        component: (
          <div className="explaination">
            <h1>User not found</h1>
            We're sorry, it seems the profie you're looking for doesn't exist.
          </div>),
      },
    ];

    return columns;
  }

  render() {
    const { errors, profileStatus } = this.props;

    const is404 = errors && errors[0]?.code === 404;

    console.warn(errors && errors[0])

    if (profileStatus === STATUS_TYPES.INITIAL ||
      profileStatus === STATUS_TYPES.PENDING) {
      return <Loader />
    }

    return <Box className="layout-box container">
      <Layout columns={is404 ? this.getColumns404() : this.getColumns()} />
    </Box>

  }
}

Profile.defaultProps = {
  firstName: "Not Found",
};

export default Profile;
