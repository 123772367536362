import React from "react";

import "./styles.scss";
import { Link } from "react-router-dom";

const Submission = ({ attachments, firstName, lastName, slug,description }) => {
    return <div className="submission">
        <h5>{description}</h5>
        <img src={`https://outdesign.club/imageApi/submissions/${attachments}.jpg`} alt={"preview"} />
        <h6>Submission by <Link to={`/u/${slug}`} >{firstName} {lastName}</Link></h6>
    </div>
}

export default Submission;