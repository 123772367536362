import React from "react";
import Toast from "./Toast";

const Toaster = ({ doRemoveToast, toasts }) => (
  <ul className="toaster">
    {toasts.map((toast, index) => (
      <Toast
        key={"toast-"+index}
        removeToast={() => doRemoveToast(toast.id)}
        {...toast}
      />
    ))}
  </ul>
);

export default Toaster;
