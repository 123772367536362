import React from "react";

import './button.scss';
import { Link } from "react-router-dom";

const Button = ({ href, text, type, onClick, className, disabled, variant }) => {
  return (
    href? 
    <Link className={`button ${type} disabled-${disabled} ${className} ${variant}`} to={href}>{text}</Link>
    :<input
      type="button"
      className={`button ${type} disabled-${disabled} ${className} ${variant}`}
      onClick={onClick ? (e) => {
        e.preventDefault();
        onClick();
      } : () => { }}
      value={text}
    /> 
  );
};

Button.defaultProps = {
  type: "primary",
  disabled: "false",
};

export default Button;
