import { ACTION_TYPES } from "constants/actionTypes";
import STATUS_TYPES from "../../constants/statusTypes";
import { groupBy } from "shared/helpers";

const initialState = {
  challengesById: {},
  status: STATUS_TYPES.INITIAL,
  challenges: [],
  challengesBySlug: {}
};

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.CHALLENGES.SET_CHALLENGES:
      return {
        ...state,
        challenges: payload,
        challengesById: groupBy('id', payload),
        challengesBySlug: groupBy('slug', payload)
      }

    case ACTION_TYPES.CHALLENGES.SET_STATUS:
      return { ...state, status: payload };

    default:
      return state;
  }
}
