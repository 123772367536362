import React from "react";

import Box from "components/shared/Box";

const ForbiddenPage = () => {
    return <Box titleText="Forbidden" variant="layout-box" className="container">
        <h3>You shouldn't be here. Your role doesn't currently allow you to view this page.</h3>
        Consider upgrading in order to have access to more features.
    </Box>

}

export default ForbiddenPage;