class ToastService {
  attachToStore(store) {
    this.dispatch = store.dispatch;
  }

  makeToast(newToast) {
    this.dispatch({ type: "ADD_TOAST", payload: newToast });
  }

  makeSuccessToast({ title, text }) {
    this.makeToast({ title, text, type: "success" });
  }

  makeErrorToast(newToast) {
    this.makeToast({ ...newToast, type: "error" });
  }
}

const toastService = new ToastService();

export default toastService;
