import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ImageUpload from "components/ImageUpload";
import Form from "components/shared/Form/form";
import Layout from "components/shared/Layout";
import Loader from "components/shared/Loader";

import STATUS_TYPES from "constants/statusTypes";
import { createChallenge, fetchChallengeBySlug, updateChallenge } from "redux/actions/challenges";
import { getChallengeBySlug, getChallengesDataStatus } from "redux/selectors/challenges";
import { getRandomString } from "shared/helpers";

import "./styles.scss";
import Box from "components/shared/Box";
import challengesAPI from "api/challengesApi";

const EditChallenge = ({ slug }) => {
  const dispatch = useDispatch();
  const challenge = useSelector((state) => getChallengeBySlug(state, slug));
  const status = useSelector(getChallengesDataStatus);

  if (slug && (!challenge?.title) && status === STATUS_TYPES.INITIAL) {
    dispatch(fetchChallengeBySlug(slug));
  }

  if (slug && (status === STATUS_TYPES.INITIAL || status === STATUS_TYPES.PENDING)) {
    return <Loader />;
  }
  const slugValue = slug || getRandomString(15);

  const { title, publishDate, deadline, html, notes, skillsImproved } = challenge;

  const publishDateLocal = (publishDate ? new Date(publishDate) : new Date(Date.now())).toLocaleDateString('en-CA');
  const deadlineDate = (deadline ? new Date(deadline) : new Date(Date.now() + 604800000)).toLocaleDateString('en-CA');

  const CHALLENGE_FORM_FIELDS = [
    {
      type: "text",
      name: "title",
      label: "Challenge Title",
      errorMessage: "Invalid Title",
      initialValue: title || 'Enter Title Here',
    },
    {
      type: "html",
      name: "html",
      label: "Challenge Brief (<500 characters)",
      errorMessage: "Invalid challenge brief",
      initialValue: html || "<p>Text here</p>",
    },
    {
      type: "html",
      name: "notes",
      label: "Challenge Notes (<200 characters)",
      errorMessage: "Invalid challenge notes",
      initialValue: notes || "<p>Have fun!</p>",
    },
    {
      type: "text",
      name: "skillsImproved",
      required: true,
      label: "Skills Improved",
      errorMessage: "Invalid skills Improved",
      initialValue: skillsImproved || "UI Design",
    },
    {
      type: "date",
      name: "publishDate",
      label: "Publish Date",
      errorMessage: "Invalid publish date",
      initialValue: publishDateLocal,
    },
    {
      type: "date",
      name: "deadline",
      label: "Deadline Date",
      errorMessage: "Invalid deadline date",
      initialValue: deadlineDate
    },
    {
      type: "text",
      name: "slug",
      label: "Slug",
      errorMessage: "Invalid slug",
      initialValue: slugValue,
      disabled: true,
      readOnly: true,
    },
    {
      type: "file",
      name: "image",
      label: "image",
      errorMessage: "Invalid image",
    },
  ];

  const submitChallenge = (formValues) => {
    const dateToTimestamp = (date) => new Date(date).valueOf();

    const formatDates = (fd) => ({
      ...fd,
      deadline: dateToTimestamp(fd.deadline),
      publishDate: dateToTimestamp(fd.publishDate)
    });

    const formData = new FormData();


    Object.entries(formatDates(formValues)).forEach(([key, value]) => {
      console.warn("APPENDING", key)
      formData.append(key, value)
    })

    console.warn(formData.entries())

    console.warn(formData)

    if (!slug) {
      challengesAPI.postChallengeData({ formData });
    } else {
      
      challengesAPI.putChallengeData({ formData, slug: formValues.slug });
    }
  };

  return <Box titleText="Challenge Form" variant="main-box">
    <Layout columns={[
      {
        component: <Form fields={CHALLENGE_FORM_FIELDS} onSubmit={submitChallenge} />,
        width: 6
      },
      {
        component: <ImageUpload slug={slugValue} imageType="challenge" />,
        width: 6
      }
    ]} />
  </Box>;
};

export default EditChallenge;
