import React from 'react';

import './layout.scss';

const Layout = ({ columns, fluid = false }) => (
  <div className={`main-container ${fluid ? 'container-fluid' : 'container'}`}>
    <div className='row'>
      {columns.map((col, index) => (
        <div className={`col col-md-${col.width}`} key={index}>{col.component}</div>
      ))}
    </div>
  </div>
);

export default Layout;
