import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw, convertFromHTML  } from 'draft-js';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.scss";

const HtmlEditor = ({ value, onChange }) => {
    const [editorState, setEditorState] = useState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(value || 'lol')
        )
      ))

    const onEditorChange = (newEditorState) => {
        const newState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setEditorState(newEditorState);
        onChange(newState);
    }
    return <div className="html-editor">
        <Editor editorState={editorState} onEditorStateChange={onEditorChange} />
    </div>
}

export default HtmlEditor;
