import React from "react";

import Layout from "components/shared/Layout";
import Box from "components/shared/Box";

import Register from "components/Register";

const columns = [
  {
    width: 4,
    component: "",
  },
  {
    width: 4,
    component: (
      <Box title={{ type: "accent", text: "Register" }}>
        <Register />{" "}
      </Box>
    ),
  },
];

class RegisterPage extends React.PureComponent {
  render() {
    return <div className="basic-page">
      <Layout columns={columns} />;
    </div>
  }
}

export default RegisterPage;
