import React from "react";
import Layout from "components/shared/Layout";
import OwnProfile from "../components/ProfileCard/OwnProfileCard.jsx";
import Box from "components/shared/Box/index.js";

const columns = [
  {
    width: 4,
    component: <OwnProfile />,
  },
  {
    width: 8,
    component: <h2 > Greatness coming soon</h2>,
  },
];

class DashboardPage extends React.PureComponent {
  render() {
    return <Box titleText="Dashboard" variant="layout-box" className="container">
      <Layout columns={columns}></Layout>
    </Box>
  }
}

export default DashboardPage;
