
export function getArticlesState(state) {
    return state.articles;
}

export function getArticlesStatus(state) {
    return getArticlesState(state).status
}

export function getArticles(state) {
    return getArticlesState(state).data
}

export function getArticle(state, id) {
    return getArticles(state)[id]
}
