import React from "react";

import Box from "components/shared/Box";
import ArticleLink from "./ArticleLink";
import Loader from "../shared/Loader";

class Blog extends React.PureComponent {
  componentDidMount() {
    const { fetchArticles } = this.props;

    fetchArticles();
  }

  render() {
    const { articles, type, className } = this.props;

    return (
      <Box
        variant={type === "small" ? "white-box" : "layout-box"}
        titleText={type === "small" ? "Other Posts" : "All Blog Posts"}
        className={`blog ${type ? `blog--${type}` : ""} ${className}`}
      >
        {!articles ||
          (articles.length === 0 && <Loader size="large" />)}
        {articles.map((article) => (
          <ArticleLink
            key={article.id}
            article={article}
            small={type === "small"}
          />
        ))}
      </Box>
    );
  }
}

Blog.defaultProps = {
  type: "",
  className: "",
};

export default Blog;
