import React from "react";

import Layout from "components/shared/Layout";

import Blog from "components/Blog";

const columns = [
  {
    width: 12,
    component: <Blog />,
  }
];

class BlogPage extends React.PureComponent {
  render() {
    return <Layout columns={columns} className="no-background" />;
  }
}

export default BlogPage;
