import React from "react";
import Form from "components/shared/Form/form";
import Box from "components/shared/Box";

const registerFormFields = [

  {
    type: "text",
    name: "firstName",
    label: "First Name",
    errorMessage: "Invalid name",
  },
  {
    type: "text",
    name: "lastName",
    label: "Last Name",
    errorMessage: "Invalid name",
  },
  {
    type: "email",
    name: "email",
    label: "Email",
    errorMessage: "Invalid Email",
  },
  {
    type: "password",
    name: "password",
    label: "Password",
    errorMessage: "Invalid Password",
  },
  {
    type: "password",
    name: "password2",
    label: "Confirm Password",
    errorMessage: "Passwords Must Match",
  },
];

class RegisterForm extends React.PureComponent {
  onSubmit = (payload) => {
    const { doPostRegister } = this.props;

    doPostRegister(payload);
  };

  render() {
    const { errors } = this.props;

    return (
      <Box className="register" variant="white-box" titleText="Sign Up">
        <Form
          fields={registerFormFields}
          onSubmit={this.onSubmit}
          formName=""
        />
        {errors && <div className="alert alert-danger">{errors}</div>}
      </Box>
    );
  }
}

export default RegisterForm;
