import Article from "./Article";

import { connect } from "react-redux";

import { fetchArticle } from "redux/actions/articles";
import { getArticle } from "redux/selectors/articles";
import { getErrors } from "redux/selectors/error";

import "./article.scss";

const mapStateToProps = (state, ownProps) => {
  return {
    errors: getErrors(state)["single-article"],
    article: getArticle(state, ownProps.id) || {}
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchArticle: (id) => {
      dispatch(fetchArticle(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Article);
 