import Login from "./Login";

import { connect } from "react-redux";
import { requestLogin } from "redux/actions/auth";
import { getAuthError } from "redux/selectors/error";

const mapStateToProps = state => {
  return {
    authError: getAuthError(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doRequestLogin: payload => {
      dispatch(requestLogin(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
 