import React from "react";

import { Navigate } from "react-router-dom";

import Login from "pages/LoginPage";
import Blog from "pages/BlogPage";
import Logout from "pages/LogoutPage";
import Homepage from "pages/HomePage";
import Settings from "pages/SettingsPage";
import Register from "pages/RegisterPage";
import Dashboard from "pages/DashboardPage";
import ArticlePage from "pages/ArticlePage";
import ProfilePage from "pages/ProfilePage";
import ChallengesPage from "pages/ChallengesPage";
import NotFound from "components/ErrorPages/notFound";
import SubmissionPage from "pages/SubmissionPage";

/* This file controls both the router and the menu.
  Routes have components & labels for both logged-in and logged-out states.
  For private routes, a Navigate to '/' is recommended
  Paths with no label (for either loggedIn, loggedOut or both) will not get rendered in the menu
*/

const APP_ROUTES = [
  {
    order: 1,
    path: "/login",
    components: {
      loggedIn: () => <Navigate to="/" />,
      loggedOut: Login,
    },
  },
  {
    order: 2,
    path: "/register",
    components: {
      loggedIn: () => <Navigate to="/" />,
      loggedOut: Register,
    },
  },
  {
    order: 2,
    path: "/settings",
    components: {
      loggedIn: Settings,
      loggedOut: Login,
    },
    labels: { loggedIn: "Settings", loggedOut: "" },
  },
  {
    order: 0,
    path: "/blog/:id",
    components: {
      loggedIn: ArticlePage,
      loggedOut: ArticlePage,
    },
  },
  {
    order: 2,
    path: "/blog",
    components: {
      loggedIn: Blog,
      loggedOut: Blog,
    },
    labels: { loggedIn: "Blog", loggedOut: "Blog" },
  },
  {
    order: 9,
    path: "/logout",
    components: {
      loggedOut: () => <Navigate to="/" />,
      loggedIn: Logout,
    },
  },
  {
    order: 0,
    path: "/u/:slug/:submissionId?",
    components: {
      loggedIn: ProfilePage,
      loggedOut: ProfilePage,
    },
  },
  {
    order: 10,
    path: "/challenges/new",
    labels: { loggedIn: "⭐ New Challenge ⭐", loggedOut: "" },
    components: {
      loggedIn: ChallengesPage,
      loggedOut: Homepage,
    },
    requiredRole: 3
  },
  {
    order: 1,
    path: "/challenges/edit/:slug",
    components: {
      loggedIn: ChallengesPage,
      loggedOut: Homepage,
    },
    requiredRole: 3
  },
  {
    order: 1,
    path: "/challenges/",
    labels: { loggedIn: "Challenges", loggedOut: "" },
    components: {
      loggedIn: ChallengesPage,
      loggedOut: Homepage,
    },
  },
  {
    order: 1,
    path: "/challenges/:slug",
    components: {
      loggedIn: ChallengesPage,
      loggedOut: Homepage,
    },
  },
  {
    order: 1,
    path: "/submissions/new/:challengeSlug",
    components: {
      loggedIn: SubmissionPage,
      loggedOut: SubmissionPage,
    },
  },
  {
    order: 0,
    path: "/",
    labels: { loggedIn: "Home", loggedOut: "Home" },
    components: {
      loggedIn: Dashboard,
      loggedOut: Homepage,
    },
  },
  {
    order: 1,
    path: "*",
    components: {
      loggedIn: NotFound,
      loggedOut: NotFound
    },
  },
];

const sortedRoutes = APP_ROUTES.concat().sort((a, b) => a.order - b.order);

export { sortedRoutes };

export default APP_ROUTES;
