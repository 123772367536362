import { ACTION_TYPES } from "constants/actionTypes";
import STATUS_TYPES from "constants/statusTypes";

const initialState = {
  accessToken: "",
  status: STATUS_TYPES.INITIAL
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.AUTH.SET_TOKEN:
      return { ...state, accessToken: action.payload };

    case ACTION_TYPES.AUTH.SET_STATUS:
      return { ...state, status: action.payload };

    case ACTION_TYPES.AUTH.UNSET_TOKEN:
      return { ...state, accessToken: "" };

    default:
      return state;
  }
};
