import React from "react";
import Logout from 'components/Logout';

class LogoutPage extends React.PureComponent {
  render() {
    return <div className="logout-page">
        <Logout></Logout>
    </div>;
  }
}

export default LogoutPage;
