import React from "react";
import Profile from "components/ProfilePage/";
import { useParams } from "react-router-dom";

const ProfilePage = () => {
  const params = useParams();

  return <Profile slug={params.slug} submissionId={params.submissionId} />
}

export default ProfilePage;
