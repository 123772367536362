/*
  IN: nestedObject: {}, pathArray: [string]
  OUT: *
  DESC: Returns the value corresponding to an object's nested keys, useful for states or constants
  
  EXAMPLE: 
    let nestedObject = { foo: {bar: 'baz'}};
    
    getObjectValueByPath(nestedObject, ['foo', 'bar']); // Will return 'baz'
*/

export const getObjectValueByPath = (nestedObject, pathArray) =>
  pathArray.reduce((o, i) => o[i], nestedObject);


/*
  IN: desired length
  OUT: string
  DESC: generates a random string, useful for slugs
*/
export const getRandomString = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

/*
  IN: error
  OUT: error message object
  DESC: JSONifies an error if possible,
       or creates an error object if not (no internet, server down, so on)
*/
export const getError = async (error) => {
  try {
    const errorJSON = await error.json();

    if (errorJSON.fields) {
      errorJSON.message += errorJSON.fields
    }

    return errorJSON;
  } catch (e) {

    return { message: "Cannot connect to the server." }
  }
}


/*
  IN: criterion(key): string, array of objects
  OUT: dictionary with the array optimized for o(1) operations
  DESC: groups item by your criteria, for example for 
  setting up a reducer of users[] & usersById{}, letting you get usersById(X) in O(1)
*/
export const groupBy = (criterion, array) => {
  const result = {};

  array.forEach(object => {
    result[object[criterion]] = object
  })

  return result;
}
