import { ACTION_TYPES } from "constants/actionTypes";
import STATUS_TYPES from "constants/statusTypes";
import userAPI from "api/userApi";

import { setToken, unsetToken } from "./auth";
import { addToast } from "./toast";
import { setError, unsetError } from "./errors";
import { getError } from "shared/helpers";

export function setUserData(data) {
  return {
    type: ACTION_TYPES.USER.SET_DATA,
    payload: data,
  };
}

export function setUserProfile(data) {
  return {
    type: ACTION_TYPES.USER.SET_PROFILE,
    payload: data,
  };
}

export function unsetData() {
  return {
    type: ACTION_TYPES.USER.UNSET_DATA,
  };
}

export function setStatus(status) {
  return {
    type: ACTION_TYPES.USER.SET_STATUS,
    payload: status,
  };
}

export function setProfileStatus(status) {
  return {
    type: ACTION_TYPES.USER.SET_PROFILE_STATUS,
    payload: status,
  };
}

export function requestUserDataIfToken() {
  return (dispatch) => {
    if (localStorage.getItem("token")) {
      return dispatch(requestUserData());
    }

    return dispatch(setStatus(STATUS_TYPES.FAILED));
  };
}

export function requestUserData(newJWT = false) {
  return async (dispatch) => {
    dispatch(setStatus(STATUS_TYPES.PENDING));

    try {
      const userData = await userAPI.fetchUserData(newJWT);
      dispatch(setUserData(userData));

      if (newJWT) {

        dispatch(setToken(userData.token));
      }
      return dispatch(setStatus(STATUS_TYPES.LOADED));

    } catch (e) {
      const error = getError(e);

      dispatch(addToast({
        type: "error",
        title: "Error",
        text: error.message || "Authentication error; please relog",
      }));

      dispatch(unsetToken());
      dispatch(setStatus(STATUS_TYPES.FAILED));


      return dispatch(
        setError({ type: "user", errors: [error.message] })
      );
    }

  }
}

export function postUserData(payload) {
  return async (dispatch) => {
    dispatch(unsetError({ type: "register-form" }));
    try {
      await userAPI.postUserData(payload)

      dispatch(
        addToast({
          type: "success",
          title: "Success!",
          text: "You can now log in.",
        }))
    } catch (e) {
      const error = getError(e);
      dispatch(
        setError({
          type: "register-form",
          errors: [
            error.message || payload
              ? payload.code === 409
                ? "Registration failed. Some of the data is already in our database;" +
                payload.fields
                : "Incorrect data; Please double check your input"
              : "Unknown error",
          ],
        })
      );
    }
  }
}

export function patchUserData(payload) {
  return async (dispatch) => {
    try {
      dispatch(
        setError({ type: "settings", errors: [] })
      );

      await userAPI.patchUserData(payload)

      dispatch(
        addToast({
          type: "success",
          title: "Success!",
          text: "Your settings have been saved.",
        })
      )

      dispatch(requestUserData(true));
    } catch (e) {
      const error = await getError(e);

      dispatch(
        setError({ type: "settings", errors: [error.message] })
      );
      
      dispatch(
        addToast({
          type: "error",
          title: "Error updating profile!",
          text: "Please check your input data",
        })
      )
    }
  }
}

export function getUserProfile(payload) {
  return (dispatch) => {
    dispatch(setProfileStatus(STATUS_TYPES.PENDING));

    return userAPI.getProfile(payload).then(
      (jsonPayload) => {
        dispatch(setUserProfile(jsonPayload));

        return dispatch(setProfileStatus(STATUS_TYPES.LOADED));
      },
      (error) => {
        dispatch(setProfileStatus(STATUS_TYPES.FAILED));

        return dispatch(
          setError({ type: "profile", errors: [{ code: 404 }] })
        );
      }
    );
  };
}
