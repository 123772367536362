import Blog from "./Blog";

import { connect } from "react-redux";
import { fetchArticles } from "redux/actions/articles";
import { getArticles } from "redux/selectors/articles";

import './Blog.scss';

const mapStateToProps = state => {
  return {
    articles: Object.values(getArticles(state))
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchArticles: () => {
      dispatch(fetchArticles());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
 