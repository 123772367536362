export function getErrors(state) {
  return state.errors;
}

export function getUserError(state) {
  return getErrors(state).user;
}

export function getAuthError(state) {
  return getErrors(state).auth;
}

export function getSettingsError(state) {
  return getErrors(state).settings;
}
