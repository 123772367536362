import { ACTION_TYPES } from "constants/actionTypes";
import STATUS_TYPES from "constants/statusTypes";

const initialState = {
  data: {},
  profile: {},
  users: [],
  role: 0,
  status: STATUS_TYPES.INITIAL,
  profileStatus: STATUS_TYPES.INITIAL,
};

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.USER.SET_DATA:
      return { ...state, data: payload };

    case ACTION_TYPES.USER.SET_USERS_DATA:
      return { ...state, users: payload };

    case ACTION_TYPES.USER.SET_PROFILE:
      return { ...state, profile: payload };

    case ACTION_TYPES.USER.SET_PROFILE_STATUS:
      return { ...state, profileStatus: payload };

    case ACTION_TYPES.USER.UNSET_DATA:
      return { ...state, data: {} };

    case ACTION_TYPES.USER.SET_STATUS:
      return { ...state, status: payload };

    case ACTION_TYPES.USER.CLEAR:
      return {};

    default:
      return state;
  }
}
