import React from "react";
import { connect } from "react-redux";

import STATUS_TYPES from "constants/statusTypes";
import { requestUserDataIfToken } from "redux/actions/user";

import { getUserData, getUserDataStatus } from "redux/selectors/user";

import Profile from "./ProfileCard";

class OwnProfile extends React.Component {
  componentDidMount() {
    const { userStatus, user } = this.props;

    if (!user.name && userStatus === STATUS_TYPES.INITIAL)
      this.props.doGetUserData();
  }

  render() {
    const { user } = this.props;

    return <Profile {...user} isOwnProfile={true}/>;
  }
}

const mapStateToProps = (state) => {
  return {
    user: getUserData(state),
    userStatus: getUserDataStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetUserData: () => {
      dispatch(requestUserDataIfToken());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnProfile);
