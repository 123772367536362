import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getChallengesData, getChallengesDataStatus } from "redux/selectors/challenges";
import { fetchChallenges } from "../../redux/actions/challenges";
import Box from "../shared/Box";
import Layout from "components/shared/Layout";

import "./styles.scss";
import { Link } from "react-router-dom";
import { IMAGE_API } from "api/const";
import STATUS_TYPES from "constants/statusTypes";
import Loader from "components/shared/Loader";


const ChallengesPage = () => {
  const dispatch = useDispatch();
  const challenges = useSelector(getChallengesData);
  const status = useSelector(getChallengesDataStatus);

  const challengesArray = challenges;

  useEffect(() => {
    dispatch(fetchChallenges());
  }, []);

  if (status === STATUS_TYPES.INITIAL) {
    return <Loader />;
  }


    return (
      <Layout columns={[
        {
          component:
            <Box titleText="Active Challenges" variant="layout-box">
              {challengesArray?.length?
                challengesArray.map(({ id, title, slug, authorSlug, firstName, lastName, deadline, html }) => (
                  <div key={`chl-${id}`} className='challenge-box'>
                    <Link to={`/challenges/${slug}`} >
                      <img src={`${IMAGE_API}/challenges/${slug}.jpg`} className="challenge-box__preview" alt="Challenge" />
                      <h1>{title}</h1>
                    </Link>
                    <div className="challenge-box__content">
                      <div dangerouslySetInnerHTML={{ __html: html }} /></div>
                    <br />
                    <Link to={`/challenges/${slug}`} >
                      View Challenge
                    </Link> by <a href={`/u/${authorSlug}`}>{firstName} {lastName}</a>. <b>Deadline:</b > {new Date(deadline * 1).toDateString()}
                  </div>
                )):<p>No challenges active.</p>}
            </Box>
        }
      ]}></Layout>
    );
};

export default ChallengesPage;
