import { ACTION_TYPES } from "constants/actionTypes";

export function setError(payload) {
  return {
    type: ACTION_TYPES.ERROR.SET_ERROR,
    payload
  };
}

export function unsetError(payload) {
  return {
    type: ACTION_TYPES.ERROR.UNSET_ERROR,
    payload
  };
}
