import Toaster from "./Toaster";

import { connect } from "react-redux";

import { getToasts } from "redux/selectors/toast";

import { removeToast } from "redux/actions/toast";

const mapStateToProps = state => {
  return {
    toasts: getToasts(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doRemoveToast: toastId => {
      dispatch(removeToast(toastId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toaster);
