import React, { useState } from "react";
import Form from "components/shared/Form/form";
import imageApi from "api/imageApi";

import "./style.scss";


const ImageUpload = ({ slug, imageType = "avatar" }) => {
  const [imageURL, setImageUrl] = useState("");
  const [imageIndex, setImageIndex] = useState(0);
  const [messages, setMessages] = useState([])

  const onSubmitImage = async (payload) => {
    setMessages([{ text: "Photo uploading...", type: "info" }])
    var formdata = new FormData();

    formdata.append("image", payload);
    formdata.append("name", slug);
    formdata.append("imageType", imageType);


    setImageUrl(`https://outdesign.club/imageApi/${imageType}s/${slug}.jpg`);
    const result = await imageApi.uploadImage(formdata, imageType, slug);

    if (result === "Success") {
      setImageIndex(imageIndex + 1);
      setMessages([{ text: "Photo upload succesful!", type: "success" }])
    } else {
      setMessages([{ text: "Photo upload failed!", type: "error" }])
    }

  };

  const IMAGE_FORM_FIELDS = [
    {
      type: "file",
      name: "image",
      label: (
        <div>
          <div className="image-container">
            <img className="image" alt={imageIndex} src={imageURL ? imageURL + "?v=" + imageIndex : `https://outdesign.club/imageApi/${imageType}s/${slug}.jpg?v=${imageIndex}`} />
          </div>
        </div>
      ),
      errorMessage: "Invalidimage",
      onFileUpload: onSubmitImage
    },
  ];

  return <Form
    fields={IMAGE_FORM_FIELDS}
    onSubmit={onSubmitImage}
    formName="Image Upload"
    className={`image-upload attempt-${imageIndex}`}
    hasSubmit={false}
    messages={messages}
  />
}

export default ImageUpload;
