import React from "react";

import { Spinner } from "react-bootstrap";

import "./Loader.scss";

const classNames = {
  loaderTypes: {
    fullScreen: "loader--global",
    hidden: "loader--hidden",
  },
  loaderSizes: {
    "full-screen": "loader--full-screen",
    small: "loader--small",
    large: "loader--large",
  },
};

const Loader = ({ type = "base", size = "base" }) => {
  const loaderType = classNames.loaderTypes[type] || "";
  const loaderSize = classNames.loaderSizes[size] || "";

  return (
    <div className={`loader ${loaderType} ${loaderSize}`}>
      <Spinner animation="border" variant="accent" />
    </div>
  );
};

export default Loader;
