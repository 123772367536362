import RegisterForm from "./RegisterForm";

import { connect } from "react-redux";
import { postUserData } from "redux/actions/user";
import { getErrors } from "redux/selectors/error";

const mapStateToProps = (state) => {
  return {
    errors: getErrors(state)["register-form"],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doPostRegister: (payload) => {
      dispatch(postUserData(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
