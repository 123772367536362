import React from "react";

import "./home.scss";

import Button from "components/shared/Button";

const HomeComponent = () => {
  return <section className="home__atf">
    <div className="home__centered-container">
      <h3>Join our community of designers supercharging their design skills</h3>
      <h1>Become a<br/>better designer</h1>
      <h2>Expand your design skillset through design challenges, community reactions and feedback from design professionals.</h2>
      <Button href="/register" className="round home__cta" text="Join the community now"></Button>
    </div></section>
}

export default HomeComponent;