import SubmissionForm from "components/SubmissionForm";
import Box from "components/shared/Box";
import React from "react";
import { useParams } from "react-router-dom";

const SubmissionPage = () => {
    const params = useParams();

    return <Box titleText="Create Submission" variant="layout-box" className="container">
        <h5>! Once you submit it, it cannot be edited !</h5>
        <SubmissionForm slug={params.challengeSlug}/>
    </Box>
}

export default SubmissionPage;