import React from "react";

import Box from "components/shared/Box";

import "./breadcrumbs.scss";

const Breadcrumbs = ({ crumbs }) => (
  <Box className="breadcrumbs">
    {crumbs.map((crumb) => (
      <a href={crumb.url} className="breadcrumbs__item">
        {crumb.label}
      </a>
    ))}
  </Box>
);

export default Breadcrumbs;