import { ACTION_TYPES } from "constants/actionTypes";
import STATUS_TYPES from "constants/statusTypes";
import challengesApi from "api/challengesApi";

import { setError } from "./errors";
import { getError } from "shared/helpers";

export function setChallenges(data) {
  return {
    type: ACTION_TYPES.CHALLENGES.SET_CHALLENGES,
    payload: data,
  };
}

// export const createChallenge = (data) => ({
//   type: ACTION_TYPES.CHALLENGES.CREATE_CHALLENGE,
//   payload: data,
// })

// export const updateChallenge = (data) => ({
//   type: ACTION_TYPES.CHALLENGES.UPDATE_CHALLENGE,
//   payload: data,
// })

export function setStatus(status) {
  return {
    type: ACTION_TYPES.CHALLENGES.SET_STATUS,
    payload: status,
  };
}

export function fetchChallenges() {
  return async (dispatch) => {
    dispatch(setStatus(STATUS_TYPES.PENDING));

    try {
      const challengesResponse = await challengesApi.getChallenges();
      dispatch(setChallenges(challengesResponse));

      return dispatch(setStatus(STATUS_TYPES.LOADED));
    } catch (e) {
      const error = await getError(e);

      dispatch(setStatus(STATUS_TYPES.FAILED));

      return dispatch(setError({ type: "challenges", errors: error }));
    }
  };
}

export function fetchChallengeBySlug(slug) {
  return async (dispatch) => {
    dispatch(setStatus(STATUS_TYPES.PENDING));

    try {
      const challengeResponse = await challengesApi.getChallengeBySlug(slug)
      dispatch(setChallenges(challengeResponse));

      dispatch(setStatus(STATUS_TYPES.LOADED));
    } catch (e) {
      const error = await getError(e);

      dispatch(setStatus(STATUS_TYPES.FAILED));

      return dispatch(setError({ type: "challenges", errors: error }));
    }
  };
}

export function createChallenge(payload) {
  return async (dispatch) => {
    dispatch(setStatus(STATUS_TYPES.PENDING));
    try {
      await challengesApi.postChallengeData(payload)

      dispatch(fetchChallenges());
    } catch (e) {
      const error = await getError(e);
      dispatch(setStatus(STATUS_TYPES.FAILED));

      return dispatch(setError({ type: "challenges", errors: error.message }));
    }
  };
}

export function updateChallenge(payload) {
  return async (dispatch) => {
    dispatch(setStatus(STATUS_TYPES.PENDING));

    try {
      await challengesApi.putChallengeData(payload.formData, payload.slug)
      dispatch(fetchChallenges());
    }
    catch (e) {

      dispatch(setStatus(STATUS_TYPES.FAILED));

      return dispatch(setError({ type: "challenges", errors: payload.error }));
    }
  }
}