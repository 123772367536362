import { ACTION_TYPES } from "constants/actionTypes";
import STATUS_TYPES from "constants/statusTypes";

const initialState = {
  data: {},
  status: STATUS_TYPES.INITIAL
};

export default function userReducer(state = initialState, {type, payload}) {
  switch (type) {
    case ACTION_TYPES.ARTICLES.SET_DATA:
      const { items } = payload;
      const articlesDict = {};

      items.forEach((article) => {
        articlesDict[article.id] = article;
      });

      return { ...state, data: articlesDict };

    case ACTION_TYPES.ARTICLES.SET_ARTICLE:
      const { data } = state;
      const newData = Object.assign({}, data);

      return { ...state, data: newData };

    case ACTION_TYPES.ARTICLES.UNSET_DATA:

      return { ...state, data: {} };

    case ACTION_TYPES.ARTICLES.SET_STATUS:

      return { ...state, status: payload };

    case ACTION_TYPES.ARTICLES.CLEAR:
      
      return {};

    default:
      return state;
  }
};
