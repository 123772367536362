import React from "react";

import Toast from "react-bootstrap/Toast";

import "./toast.scss";

class CustomToast extends React.PureComponent {
  componentDidMount() {
    const { removeToast } = this.props;

    this.timeout = setTimeout(() => {
      removeToast();
    }, 4000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { removeToast, type, text, title } = this.props;

    return (
      <Toast onClose={removeToast} className={`toast toast--${type}`}>
        <Toast.Header className="toast__header">
          <b>{title}</b>
        </Toast.Header>
        <Toast.Body className="toast__body">{text}</Toast.Body>
      </Toast>
    );
  }
}

export default CustomToast;
