import React from "react";

import "./formField.scss";
import HtmlEditor from "./Html";
import FileInput from "./FileInput";

const FormField = ({
  name,
  label,
  type,
  value,
  onChange,
  isInvalid,
  errorMessage,
  className,
  readonly,
}) => (
  <div
    className={`field-input field-${name} ${isInvalid && "invalid"
      } ${className}`}
  >
    <label htmlFor={name}> {label} </label>

    {(() => {
      switch (type) {
        case "textarea":
          return (
            <textarea
              value={value}
              name={name}
              id={name}
              readOnly={readonly}
              onChange={(e) => onChange(e.target.value)}
            ></textarea>
          );
        case "dropdown":
          return (
            <select
              value={value}
              name={name}
              id={name}
              readOnly={readonly}
              onChange={(e) => onChange(e.target.value)}
            ></select>
          );

        case "file":
          return (
            <FileInput
              id={name}
              name={name}
              type={type}
              readOnly={readonly}
              onChange={onChange}
              value={value}
            />
          );

        case "html":
          return (
            <HtmlEditor
              id={name}
              name={name}
              type={type}
              value={value}
              readOnly={readonly}
              onChange={(newHtml) => {
                onChange(newHtml);
              }}
            />
          );

        default:
          return (
            <input
              id={name}
              name={name}
              type={type}
              value={value}
              readOnly={readonly}
              onChange={(e) => onChange(e.target.value)}
            />
          );
      }
    })()}
    {isInvalid && errorMessage && (
      <span className="error-message">{errorMessage}</span>
    )}
  </div>
);

FormField.defaultProps = {
  className: "",
};

export default FormField;
