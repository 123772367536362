import React from "react";
import { connect } from "react-redux";

import STATUS_TYPES from "constants/statusTypes";
import { requestUserDataIfToken, patchUserData } from "redux/actions/user";

import { getUserData, getUserDataStatus } from "redux/selectors/user";

import SettingsForm from "./SettingsForm";
import { getSettingsError } from "redux/selectors/error";

class OwnSettingsForm extends React.Component {
  componentDidMount() {
    const { userStatus, user } = this.props;

    if (!user.name && userStatus === STATUS_TYPES.INITIAL)
      this.props.doGetUserData();
  }

  render() {
    const {
      user,
      getUserData,
      getUserDataStatus,
      doGetUserData,
      doPatchUserData,
      settingsErrors
    } = this.props;

    return (
      <SettingsForm
        {...user}
        getUserData={getUserData}
        getUserDataStatus={getUserDataStatus}
        doGetUserData={doGetUserData}
        doPatchUserData={doPatchUserData}
        isOwnSettings={true}
        settingsErrors={settingsErrors}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: getUserData(state) || { name: "" },
    userStatus: getUserDataStatus(state),
    settingsErrors: getSettingsError(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetUserData: () => {
      dispatch(requestUserDataIfToken());
    },
    doPatchUserData: (payload) => {
      dispatch(patchUserData(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnSettingsForm);
