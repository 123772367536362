import React from "react";
import Login from "components/Login";
import Layout from "components/shared/Layout";

const columns = [
  { width: 4, component: <div></div> },
  { width: 4, component: <Login></Login> },
];

class LoginPage extends React.PureComponent {
  render() {
    return <div className="login-page basic-page">
      <Layout columns={columns} />;
    </div>
  }
}

export default LoginPage;
