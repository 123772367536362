import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Nav from "../Nav";

import Loader from "components/shared/Loader";
import Toaster from "components/shared/Toaster";

import APP_ROUTES from "constants/appRoutes";
import STATUS_TYPES from "constants/statusTypes";
import ForbiddenPage from "components/ErrorPages/forbidden";

class AppRouter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { hasCheckedAuthentication: false };
  }

  componentDidMount() {
    const { doGetUserData } = this.props;

    doGetUserData();
  }

  componentDidUpdate() {
    const { userStatus } = this.props;
    const { hasCheckedAuthentication } = this.state;

    if (
      !hasCheckedAuthentication &&
      (userStatus === STATUS_TYPES.LOADED || userStatus === STATUS_TYPES.FAILED)
    ) {
      this.setState({ hasCheckedAuthentication: true });
    }
  }

  render() {
    const { isLoggedIn, user } = this.props;
    const { hasCheckedAuthentication } = this.state;

    return (
      <Router>
        <Nav user={user} />
        <div className="min-height-container">
          <Toaster />
          <Routes>
            {APP_ROUTES.map((route) => {
              if (route.requiredRole && route.requiredRole > user?.role) {
                return <Route
                path={route.path}
                element={<ForbiddenPage />}
                key={route.path}
                {...(route.extraProps || {})}
              />
              }

              const RouteElement = isLoggedIn
                ? route.components.loggedIn
                : route.components.loggedOut

              return <Route
                path={route.path}
                element={
                  <RouteElement />
                }
                key={route.path}
                {...(route.extraProps || {})}
              />
            }
            )}
          </Routes>
          <Loader
            type={hasCheckedAuthentication ? "hidden" : ""}
            size="full-screen"
          />
        </div>
        <footer>&copy; Outdesign.Club</footer>
      </Router>
    );
  }
}

export default AppRouter;
