import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import STATUS_TYPES from "constants/statusTypes";

import { getChallengeBySlug, getChallengesDataStatus } from "redux/selectors/challenges";
import { fetchChallengeBySlug } from "../../redux/actions/challenges";
import Box from "../shared/Box";

import { Link } from "react-router-dom";
import Loader from "components/shared/Loader";
import { IMAGE_API } from "api/const";
import { getUserData } from "redux/selectors/user";

import "./styles.scss";
import Layout from "components/shared/Layout";
import Button from "components/shared/Button";
import submissionsAPI from "api/submissionsApi";
import Submission from "components/Submission";


const OneChallenge = ({ challengeSlug }) => {
  const [submissions, setSubmissions] = useState([])
  const dispatch = useDispatch();
  const challenge = useSelector((state) => getChallengeBySlug(state, challengeSlug));
  const status = useSelector(getChallengesDataStatus);
  const user = useSelector(getUserData);

  if ((!challenge?.title) && status === STATUS_TYPES.INITIAL) {
    dispatch(fetchChallengeBySlug(challengeSlug));
  }


  const whatever = async () => {
    try {
      const chl = await submissionsAPI.getSubmissionsByChallenge(challengeSlug);
      setSubmissions(chl);
    }
    catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    whatever();
    return () => { }
  }, [])

  const { id, title, slug, deadline, html, skillsImproved, notes } = challenge;

  if (status === STATUS_TYPES.INITIAL || status === STATUS_TYPES.PENDING) {
    return <Loader />;
  }

  return (
    <div className="container one-challenge">
      <Box variant="main-box" subTitleText="Current Challenge" titleText={title}>
        {user.role > 3 && <Link to={`/challenges/edit/${slug}`}> <h4>⭐Edit Challenge⭐</h4></Link>}

        <div className="one-challenge__info">
          <div className="one-challenge__info-box">
            <div className="title">Deadline</div>
            <div className="value">{new Date(deadline * 1).toLocaleDateString()}</div>
          </div>
          <div className="one-challenge__info-box">
            <div className="title">Skills Improved</div>
            <div className="value">{skillsImproved}</div>
          </div>
        </div>
        <Layout fluid
          columns={[{
            width: 7,
            component: <div>
              <img src={`${IMAGE_API}/challenges/${slug}.jpg`} alt="Challenge" className="one-challenge__image" />
            </div>
          }, {
            width: 5,
            component: <div>
              <div key={`chl-${id}`} className='one-challenge__brief'>
                <h3 className="one-challenge__brief-title">Challenge brief</h3>
                <div className="one-challenge__brief-text" dangerouslySetInnerHTML={{ __html: html }} />
                <div className="one-challenge__brief-notes" >
                  <div className="note-label">Note:</div>
                  <div className="note-value" dangerouslySetInnerHTML={{ __html: notes }}></div>
                </div>
              </div></div>
          }]}>

        </Layout>
        <br />
        <br />
        <br />
        <br />
        <Layout fluid columns={[{
          width: 4,
          component: <div>
            <h3>How it works.</h3>
            1. Sign up</div>
        }, {
          width: 4,
          component: <div><h3> 2. join a challenge</h3>
            <Button
              href={`/submissions/new/${challenge.slug}`}
              text="Join this challenge now"
              type="round" />
          </div>
        }, {
          width: 4,
          component: <div><h3>3. receive feedback</h3>
            Wait until sfitul asteapta & We'll get back to you
          </div>
        }]} />
      </Box>
      {
        submissions.length ? <Box variant="layout-box" subTitleText="Submissions" >

          <div className="submissions">
            {submissions.map(submission => <Submission {...submission} />)}
          </div>
        </Box> : ''}
    </div>
  );
};

export default OneChallenge;
