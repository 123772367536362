import ProfilePage from "./Profile";

import { connect } from "react-redux";

import { getUserProfile } from "redux/actions/user";
import { getUserProfile as getUserProfileSelector, getUserProfileStatus } from "redux/selectors/user";
import { getErrors } from "redux/selectors/error";

import "./profile.scss";

const mapStateToProps = (state, ownProps) => {
  return {
    errors: getErrors(state)["profile"],
    profile: getUserProfileSelector(state, ownProps.id) || {},
    profileStatus: getUserProfileStatus(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserProfile: (slug) => {
      dispatch(getUserProfile(slug));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
