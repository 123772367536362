import React from "react";

import Form from "components/shared/Form/form";
import Box from "components/shared/Box";
import ImageUpload from "components/ImageUpload";

import "./settingsForm.scss";
import imageApi from "api/imageApi";

class SettingsForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: "",
      imageDeleteHash: "",
      user: props.user,
    };
  }

  onSubmit = async (payload) => {
    const {  id } = this.props;

    const formData = new FormData();

    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value)
    })

    const result = await imageApi.uploadUserForm(formData,id);
  };

  render() {
    const { avatar, email, firstName, slug, lastName, settingsErrors } = this.props;

    if (!firstName) return false;

    const INFO_FORM_FIELDS = [
      {
        type: "text",
        name: "firstName",
        label: "Full Name",
        errorMessage: "Invalid Name",
        initialValue: firstName,
      },
      {
        type: "text",
        name: "lastName",
        label: "Full Name",
        errorMessage: "Invalid Name",
        initialValue: lastName,
      },
      {
        type: "email",
        name: "email",
        label: "Email",
        errorMessage: "Invalid email",
        initialValue: email,
      },
      {
        type: "text",
        name: "slug",
        label: "Slug",
        errorMessage: "Invalid slug",
        initialValue: slug,
      },
      {
        type: "file",
        name: "image",
        label: "image",
        errorMessage: "Invalid image",
      },
    ];

    return (
      <Box
        title={{ text: "Settings", type: "accent" }}
        className="settings-form"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <Form
                fields={INFO_FORM_FIELDS}
                onSubmit={this.onSubmit}
                formName="Info"
                className="settings-form__info-form"
                messages={(settingsErrors || []).map(error => ({ type: "error", text: error }))}
              />
            </div>
          </div>
        </div>
      </Box>
    );
  }
}

export default SettingsForm;
