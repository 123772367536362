import React from "react";

const prettyDate = (date) => {
    const dateObject = new Date(date);
    
    return dateObject.toLocaleString();
};

const ArticleLink = ({ article, small }) => (
  <a
    href={`/blog/${article.id}`}
    className={`article-link ${small && "article-link--small"}`}
  >
    <article>
      <h2 className="article-link__title">{article.title}</h2>
      <span className="article-link__author">
        {article.author && article.author.displayName}
      </span>
      <span className="article-link__date">
        {prettyDate(article.published)}
      </span>
      {article.labels &&
        article.labels.map((label) => (
          <span className="article-link__label">{label}</span>
        ))}
    </article>
  </a>
);

export default ArticleLink;