import React from "react";
import Box from "components/shared/Box";

import "./profile.scss";

const ROLES = ["Free User", "Premium User", "Premium", "Mentor", "Admin"];

const Profile = ({
  isOwnProfile,
  firstName,
  lastName,
  slug,
  role
}) => (
  <Box className={`profile ${isOwnProfile ? "profile--own" : ""}`}>
    <div className="profile__image">
      <img src={`https://outdesign.club/imageApi/avatars/${slug}.jpg`} alt="Profile" />
    </div>
    <h2 className="profile__name">
      {firstName} {lastName}
    </h2>
    <div className="profile__role">{ROLES[role]}</div>
  </Box>
);

export default Profile;
