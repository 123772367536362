import APIHandler from "./apiHandler";

import { API_ROUTES } from "./const";
var requestOptions = {
  headers: new Headers({ authorization: localStorage.getItem('token') }),
  method: "POST",
  redirect: "follow",
};
const challengesAPI = {
  getChallenges: async () =>
    await APIHandler.get({ url: API_ROUTES.CHALLENGES }),
  getChallengeBySlug: async (slug) =>
    await APIHandler.get({ url: API_ROUTES.CHALLENGES + slug }),
  postChallengeData: async ({formData}) => {
    requestOptions.body = formData;

    for (const pair of formData.entries()) {
      console.warn(`${pair[0]}, ${pair[1]}`);
    }
    await fetch(API_ROUTES.CHALLENGES , {
      headers: new Headers({ authorization: localStorage.getItem('token') }),
      body: formData,
      method: "POST"
    })
  },
  putChallengeData: async ({ formData, slug }) => {
    requestOptions.body = formData;

    console.warn("TPF", slug)

    for (const pair of formData.entries()) {
      console.warn(`${pair[0]}, ${pair[1]}`);
    }
    await fetch(API_ROUTES.CHALLENGES + slug, {
      headers: new Headers({ authorization: localStorage.getItem('token') }),
      body: formData,
      method: "POST"
    })
  }
};

export default challengesAPI;
