import React from "react";
import { Provider } from "react-redux";
import { createRoot } from 'react-dom/client';

import store from "./redux/reducers";
import toastService from "shared/toastService";

import App from "./App";

import "./index.scss";

toastService.attachToStore(store);

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
