import APIHandler from "./apiHandler";

import { API_ROUTES } from "./const";
var requestOptions = {
    headers: new Headers({ authorization: localStorage.getItem('token') }),
    method: "POST",
    redirect: "follow",
};
const submissionsAPI = {
    getSubmissionsByChallenge: async (challengeSlug) =>
        await APIHandler.get({ url: API_ROUTES.SUBMISSIONS + "challenge/" + challengeSlug }),
    getSubmissionBySlug: async (slug) =>
        await APIHandler.get({ url: API_ROUTES.SUBMISSIONS + slug }),
    postSubmissionData: async ({ formData }) => {
        requestOptions.body = formData;

        for (const pair of formData.entries()) {
            console.warn(`${pair[0]}, ${pair[1]}`);
        }
        await fetch(API_ROUTES.SUBMISSIONS, {
            headers: new Headers({ authorization: localStorage.getItem('token') }),
            body: formData,
            method: "POST"
        })
    },
    putSubmissionData: async ({ formData, slug }) => {
        requestOptions.body = formData;

        for (const pair of formData.entries()) {
            console.warn(`${pair[0]}, ${pair[1]}`);
        }
        await fetch(API_ROUTES.SUBMISSIONS + slug, {
            headers: new Headers({ authorization: localStorage.getItem('token') }),
            body: formData,
            method: "POST"
        })
    }
};

export default submissionsAPI;
