export function getChallengesState(state) {
    return state.challenges;
}

export function getChallengesData(state) {
    return getChallengesState(state).challenges;
}

export function getChallengesDataStatus(state) {
    return getChallengesState(state).status;
}

export function getChallengeBySlug(state, slug) {
    return getChallengesState(state).challengesBySlug[slug] || {};
}
