import { ACTION_TYPES } from "constants/actionTypes";
import STATUS_TYPES from "constants/statusTypes";
import articlesAPI from "api/articlesApi";

import { setError } from "./errors";

export function setArticles(data) {
  return {
    type: ACTION_TYPES.ARTICLES.SET_DATA,
    payload: data,
  };
}

export function setArticle(data) {
  return {
    type: ACTION_TYPES.ARTICLES.SET_ARTICLE,
    payload: data,
  };
}

export function unsetData() {
  return {
    type: ACTION_TYPES.ARTICLES.UNSET_DATA,
  };
}

export function setStatus(status) {
  return {
    type: ACTION_TYPES.ARTICLES.SET_STATUS,
    payload: status,
  };
}

export function fetchArticles() {
  return (dispatch) => {
    dispatch(setStatus(STATUS_TYPES.PENDING));

    return articlesAPI.getArticles().then(
      (jsonPayload) => {
        dispatch(setArticles(jsonPayload));

        return dispatch(setStatus(STATUS_TYPES.LOADED));
      },
      (response) => {
        dispatch(setStatus(STATUS_TYPES.FAILED));

        response.then((payload) => {
          return dispatch(
            setError({ type: "articles", errors: payload.error })
          );
        });
      }
    );
  };
}

export function fetchArticle(articleId) {
  return (dispatch) => {
    dispatch(setStatus(STATUS_TYPES.PENDING));

    return articlesAPI.getArticleById(articleId).then(
      (jsonPayload) => {
        dispatch(setArticle(jsonPayload));

        return dispatch(setStatus(STATUS_TYPES.LOADED));
      },
      (response) => {
        dispatch(setStatus(STATUS_TYPES.FAILED));

        response.then((payload) => {
          return dispatch(
            setError({ type: "single-article", errors: payload.error })
          );
        });
      }
    );
  };
}
