import React from "react";

import { sortedRoutes } from "constants/appRoutes";
import NavItem from "./NavItem/NavItem";

import Logo from "../../assets/logo.png"

import "./nav.scss";
import Button from "components/shared/Button";

export class Nav extends React.Component {
  render() {
    const { isLoggedIn, user } = this.props;

    return (
      <nav className="main-nav">
        <div className="container">
          <div className="main-nav__logo">
            <a href="/">
              <img src={Logo} alt="Logo" />
            </a>
          </div>
          <ul className="main-nav__menu">
            {sortedRoutes.map((route) => {
              if (route.requiredRole && route.requiredRole > user.role) return ""
              return <NavItem key={route.path} route={route} isLoggedIn={isLoggedIn} />
            }
            )}
          </ul>
          {!isLoggedIn ? <ul className="main-nav__auth">
            <li className="main-nav__item">
              <Button type="round-inverse" href="/login" text="Log In" />
            </li>
            <li className="main-nav__item">
              <Button type="round" href="/register" text="Sign Up" />
            </li>
          </ul>:<ul className="main-nav__auth">
            <li className="main-nav__item">
              <Button type="round-inverse" href="/logout" text="Log Out" />
            </li>
          </ul>}
        </div>
      </nav>
    );
  }
}

export default Nav;
