import { ACTION_TYPES } from "constants/actionTypes";

export function addToast(payload) {
  return {
    type: ACTION_TYPES.TOAST.ADD_TOAST,
    payload
  };
}

export function removeToast(payload) {
  return {
    type: ACTION_TYPES.TOAST.REMOVE_TOAST,
    payload
  };
}
