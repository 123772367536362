import { getError } from "shared/helpers";

var requestOptions = {
  headers: new Headers({ authorization: localStorage.getItem('token') }),
  method: "POST",
  redirect: "follow",
};

const imageApi = {
  uploadImage: async (formData, imageType, slug) => {

    requestOptions.body = formData;

    try {
      let result;
      if (imageType === "avatar") {
        result = await fetch(`${process.env.REACT_APP_API_URL}/users/image`, requestOptions);
      } else {
        result = await fetch(`${process.env.REACT_APP_API_URL}/challenges/image/${slug}`, requestOptions);
      }

      return result.text()
    } catch (e) {
      throw "ERROR";
    }
  }
  ,
  uploadUserForm: async (formData, id) => {
    try {
      requestOptions.body = formData;
      const result = await fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, requestOptions);
      return result;
    } catch (e) {
      return getError(e)
    }
  }
};

export default imageApi;
