import React from "react";
import HomeComponent from "../components/Home";

const HomePage = () => (
  <div className="page homepage">
    <HomeComponent />
  </div>
);

export default HomePage;
