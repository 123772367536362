import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ImageUpload from "components/ImageUpload";
import Form from "components/shared/Form/form";
import Layout from "components/shared/Layout";
import Loader from "components/shared/Loader";

import STATUS_TYPES from "constants/statusTypes";
import { fetchChallengeBySlug } from "redux/actions/challenges";
import { getChallengeBySlug, getChallengesDataStatus } from "redux/selectors/challenges";
import { getRandomString } from "shared/helpers";

import "./styles.scss";
import submissionsAPI from "api/submissionsApi";
import { addToast } from "redux/actions/toast";
import { useNavigate } from "react-router-dom";

const SubmissionForm = ({ slug }) => {
  const dispatch = useDispatch();
  const challenge = useSelector((state) => getChallengeBySlug(state, slug));
  const status = useSelector(getChallengesDataStatus);
  const navigate = useNavigate();

  if (slug && (!challenge?.title) && status === STATUS_TYPES.INITIAL) {
    dispatch(fetchChallengeBySlug(slug));
  }

  if (slug && (status === STATUS_TYPES.INITIAL || status === STATUS_TYPES.PENDING)) {
    return <Loader />;
  }
  
  const CHALLENGE_FORM_FIELDS = [
    {
      type: "textarea",
      name: "description",
      label: "Description (<200 characters)",
      errorMessage: "Invalid challenge brief",
      initialValue: "Briefly describe your submission",
    },
    {
      type: "file",
      name: "image",
      label: "image (1000 x 6000 max size < 3Mb)",
      errorMessage: "Invalid image",
    },
  ];


  const submitChallenge = async (formValues) => {

    const formData = new FormData();


    Object.entries(formValues).forEach(([key, value]) => {
      formData.append(key, value)
    });

    formData.append("challengeSlug", challenge.slug)

    try {
      await submissionsAPI.postSubmissionData({ formData });
      dispatch(addToast({ type: "success", title: "Success!", text: "Your submission has been posted!" }))
      navigate('/challenges')
    } catch (e) {

      dispatch({ type: "error", title: "Something went wrong" })
    }
  };

  return <div>
    <h3>Submission for {challenge?.title}</h3>
    <Layout columns={[
      {
        component: <Form fields={CHALLENGE_FORM_FIELDS} onSubmit={submitChallenge} />,
        width: 6
      },
    ]} />
  </div>
};

export default SubmissionForm;
