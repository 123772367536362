import { connect } from "react-redux";

import {getUserData} from "redux/selectors/user";
import {getIsLoggedIn} from "redux/selectors/auth";

import Nav from "./nav";

const mapStateToProps = state => {

  return {
    userData: getUserData(state),
    isLoggedIn: getIsLoggedIn(state)
  };
};

export default connect(mapStateToProps)(Nav);
