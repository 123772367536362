
export function getUserState(state) {
    return state.user;
}

export function getUserData(state) {
    return getUserState(state).data
}


export function getUserProfile(state) {
    return getUserState(state).profile
}


export function getUserList(state) {
    return getUserState(state).users
}

export function getUserDataStatus(state) {
    return getUserState(state).status
}

export function getUserProfileStatus(state) {
    return getUserState(state).profileStatus
}
