import APIHandler from "./apiHandler";

import { API_ROUTES } from "./const";

const userAPI = {
  fetchUserData: async (newJWT) =>
    await APIHandler.get({ url: `${API_ROUTES.ME}${newJWT ? 'true' : ''}` }),
  getProfile: async ({ slug, submissionId }) =>
    submissionId
      ? await APIHandler.get({ url: API_ROUTES.PROFILES + slug + "/" + submissionId })
      : await APIHandler.get({ url: API_ROUTES.PROFILES + slug }),
  patchUserData: async (payload) => {
    
    for (const pair of payload.formData.entries()) {
      console.warn(`${pair[0]}, ${pair[1]}`);
    }

    return await APIHandler.post({
      url: API_ROUTES.USER +"AAA"+ payload.userId,
      body: payload.formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  postUserData: async (payload) =>
    await APIHandler.post({
      url: API_ROUTES.USER,
      body: payload,
    }),
};

export default userAPI;
