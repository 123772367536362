import fetch from "cross-fetch";

import ToastService from "shared/toastService";

export class APIhandler {
  request({ url, headers, body, method }) {
    const token = localStorage.getItem("token");

    const defaultHeaders = {
      "Content-Type": "application/json",
      authorization: token
    };

    const requestHeaders = headers
      ? {
        ...defaultHeaders,
        ...headers
      }
      : defaultHeaders;

    try {
      return fetch(url, {
        headers: requestHeaders,
        body: JSON.stringify(body),
        method
      }).then(
        r => {

          if (r.status < 400) {
            return r.json();
          }

          // A whitelist-based middleware can be instated here for handling 
          //   non-generic 4xx errors later on, based on route & response.
          // This is TBD, as making toasts based on speciffic scenarios might be
          //   cleaner if done as thunk side effects.

          if (r.status === 500) {
            ToastService.makeErrorToast({
              title: "Server Error",
              text: `We're sorry, a server error has occured.`
            });
          }

          throw r;
        },
        error => {
          throw error;
        }
      );
    } catch (e) {
      throw e
    }
  }

  get({ url, headers, body }) {
    return this.request({ url, headers, body, method: "GET" });
  }

  post({ url, headers, body }) {
    return this.request({ url, headers, body, method: "POST" });
  }

  put({ url, headers, body }) {
    return this.request({ url, headers, body, method: "PUT" });
  }

  patch({ url, headers, body }) {
    return this.request({ url, headers, body, method: "PATCH" });
  }

  delete({ url, headers, body }) {
    return this.request({ url, headers, body, method: "DELETE" });
  }
}

const API = new APIhandler();

export default API;
