import React from "react";

import Box from "components/shared/Box";

const parse = content => <div dangerouslySetInnerHTML={{__html: content}}></div>

class Article extends React.PureComponent {
  componentDidMount() {
    const { fetchArticle, article, id } = this.props;

    if (!article.title) {
      fetchArticle(id);
    }
  }


  render() {
    const { article } = this.props;

    return (
      <Box titleText={article?.title} variant="layout-box">
        <article className="article">
          <main>{parse(article.content)}</main>
        </article>
      </Box>
    );
  }
}

export default Article;
