import React from "react";

import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

import Challenges from "components/ChallengesPage";
import OneChallenge from "components/OneChallenge";
import EditChallenge from "components/EditChallenge";
import { getUserData } from "redux/selectors/user";

const ChallengesPage = () => {
  const userData = useSelector(getUserData);
  const params = useParams();
  const location = useLocation();

  if (location.pathname === '/challenges/new') {
    return <EditChallenge />
  } else if ((location.pathname + "").includes("/challenges/edit/")) {
    return <EditChallenge slug={params.slug} />
  }

  return params.slug? <OneChallenge challengeSlug={params.slug} />: <Challenges />
}

export default ChallengesPage;
