import STATUS_TYPES from "constants/statusTypes";

import {getUserDataStatus} from './user';

export function getToken(state) {
  return state.authentication.token;
}

export function getIsLoggedIn(state) {
  return getUserDataStatus(state) === STATUS_TYPES.LOADED;
}