import React from "react";

import propTypes from "prop-types";

import "./box.scss";


const Box = ({ children, className, variant, titleText, subTitleText }) => (
  <div className={`box ${className} ${variant}`}>
    {subTitleText && <h6 className="box-subTitle"> {subTitleText} </h6>}
    {titleText && <h1 className="box-title">{titleText}</h1>}

    {children}
  </div>
);

Box.defaultProps = {
  className: "",
  variant: "primary"
};

Box.propTypes = {
  className: propTypes.string,
  subTitleText: propTypes.string,
  titleText: propTypes.string
};

export default Box;
